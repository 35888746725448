import Pptxgen from 'pptxgenjs';

import {
  ARR_ARR_TOTAL_COST_CALCULATION_ROW_TEXT,
  ARR_ARR_TOTAL_COST_SUMMARY_ROW_TEXT,
  ARR_ARR_TOTAL_LEFTOVER_PER_COVER_CALCULATION_ROW_TEXT,
  ARR_ARR_TOTAL_LEFTOVER_PER_COVER_SUMMARY_ROW_TEXT,
  ARR_ARR_TOTAL_WEIGHT_CALCULATION_ROW_TEXT,
  ARR_ARR_TOTAL_WEIGHT_SUMMARY_ROW_TEXT,
  SLIDE_STYLE,
} from '../constant/LocationMonthlyReviewPowerpoint';
import {
  IssueWasteAnalysis,
  MenuItemWasteAnalysis,
  ReductionInCostSummary,
  ReductionInWeightSummary,
  StationWasteAnalysis,
  WastePerCoverSummary,
} from '../interfaces';

const LUMITICS_DASHBOARD_URL = 'https://dashboard.lumitics.com/';

/**
 * This function as created to convert large numbers (with no. of digits greater than 6) into an abbreviated form with 2dp.
 * This is to ensure that numbers with number of digits larger than 6 do not end up creating a extremely long and hard to
 * read number that is not user friendly in terms of UX for the end user. For numbers >= 10 and below 1M, it is rounded off
 * to the nearest whole number and every 3 digits is separated by a comma. For numbers below 10, it is rounded off to 2dp.
 * Look at "arrAbbreviatedSymbolReference" to see abbreviations created for different numbers of digits.
 * @param value - Number to be formatted
 * @returns abbreviatedValueInString - Number in string form that has been formatted accordingly
 */
const convertToAbbreviatedNumber = (value: number) => {
  const arrAbbreviatedSymbolReference = [
    { value: 1e9, divisor: 1e9, symbol: 'B' },
    { value: 1e6, divisor: 1e6, symbol: 'M' },
    { value: 1e3, divisor: 1e3, symbol: '' },
    { value: 1e1, divisor: 1, symbol: '' },
    { value: 0, divisor: 0, symbol: '' },
  ];
  // Number between 1000 and 1000000 is handled differently as every 3 digits before the decimal point is
  // to be separated by a comma
  const matchedAbbreviatedSymbolReference = arrAbbreviatedSymbolReference.find(
    (abbreviatedSymbolReference) => value >= abbreviatedSymbolReference.value
  );
  // No abbreviation for number between 1000 and 1000000
  if (matchedAbbreviatedSymbolReference!.divisor === 1e3) {
    return Math.round(value).toLocaleString('en-US');
  }
  if (matchedAbbreviatedSymbolReference!.divisor === 1) {
    return Math.round(value);
  }
  if (matchedAbbreviatedSymbolReference!.divisor === 0) {
    return value.toFixed(2);
  }
  return (
    (value / matchedAbbreviatedSymbolReference!.divisor).toFixed(2) +
    matchedAbbreviatedSymbolReference!.symbol
  );
};

/**
 * This function adds and initialises a slide depending on the slide type passed in
 * @param pres - Variable referencing the current working presentation. This is required to add a new slide
 * @param type - Type of slide to be initialised - 'opening', 'section', 'closing' and 'plain'
 * @param text - Text to be placed on slide upon initialisation (i.e. header text, opening text, closing email address, etc.)
 */
const initialiseSlide = (pres: Pptxgen, type: string, text?: string): Pptxgen.Slide => {
  const slide = pres.addSlide();
  switch (type) {
    case SLIDE_STYLE.opening: {
      slide.background = { path: 'openingBackground.png' };
      slide.addText(text!, {
        fontFace: 'Dosis',
        color: '#FFFFFF',
        fontSize: 16,
        align: 'center',
        x: 1.06,
        y: 3.8,
        h: 1,
        w: 4,
      });
      break;
    }
    case SLIDE_STYLE.section: {
      slide.background = { path: 'sectionBackground.png' };
      slide.addText('', {
        shape: pres.ShapeType.rect,
        fill: { color: '#102547' },
        bold: true,
        fontFace: 'Dosis',
        color: '#FFFFFF',
        fontSize: 12,
        x: 0,
        y: 0.4,
        w: 5.5,
        h: 0.35,
      });
      slide.addText(text!, {
        bold: true,
        fontFace: 'Dosis',
        align: 'center',
        color: '#e4745e',
        fontSize: 14,
        x: 3.5,
        y: 2.6,
        w: 3,
        h: 0.35,
      });
      break;
    }
    case SLIDE_STYLE.subSection: {
      // slide.background = { path: 'sectionBackground.png' };
      slide.addText('', {
        shape: pres.ShapeType.rect,
        fill: { color: '#102547' },
        bold: true,
        fontFace: 'Dosis',
        color: '#FFFFFF',
        fontSize: 12,
        x: 0,
        y: 0.4,
        w: 5.5,
        h: 0.35,
      });
      slide.addText(text!, {
        bold: true,
        fontFace: 'Dosis',
        align: 'center',
        color: '#e4745e',
        fontSize: 14,
        x: 3.5,
        y: 2.6,
        w: 3,
        h: 0.35,
      });
      break;
    }
    case SLIDE_STYLE.closing: {
      slide.background = { path: 'closingBackground.png' };
      slide.addText('lumitics.com/', {
        x: 2,
        y: 3.78,
        w: 3.5,
        h: 0.5,
        fontFace: 'Dosis',
        color: '#FFFFFF',
        fontSize: 18,
      });
      slide.addText(text!, {
        x: 6.07,
        y: 3.78,
        w: 3.5,
        h: 0.5,
        fontFace: 'Dosis',
        color: '#FFFFFF',
        fontSize: 18,
      });
      slide.addText('facebook.com/lumitics/', {
        x: 2,
        y: 4.78,
        w: 3.5,
        h: 0.5,
        fontFace: 'Dosis',
        color: '#FFFFFF',
        fontSize: 18,
      });
      slide.addText('71 Ayer Rajah Crescent, #07-10\nSingapore 139951', {
        x: 6.07,
        y: 4.78,
        w: 3.5,
        h: 0.5,
        fontFace: 'Dosis',
        color: '#FFFFFF',
        fontSize: 18,
      });
      break;
    }
    case SLIDE_STYLE.plain: {
      slide.background = { path: 'plainBackground.png' };
      slide.addText(text!, {
        shape: pres.ShapeType.rect,
        fill: { color: '#102547' },
        bold: true,
        fontFace: 'Dosis',
        color: '#FFFFFF',
        fontSize: 12,
        x: 0,
        y: 0.4,
        w: 5.5,
        h: 0.35,
      });
      break;
    }
  }
  return slide;
};

/**
 * This function inserts graph images to the current working slide
 * @param slide - Variable referencing the current working slide
 * @param graphImage - Base 64 string of the graph image
 * @param headerText - Header text used for discriminating the content to be placed beside the graphs
 * @param isFullWidth - Indicates if the graph is to fill up the entire width of the slide
 * @param wastePerCoverSummary - Waste per cover values required for graph subtexts
 */
const insertGraph = (
  slide: Pptxgen.Slide,
  graphImage: string,
  headerText: string,
  isFullWidth?: boolean,
  wastePerCoverSummary?: Partial<WastePerCoverSummary>
) => {
  if (graphImage !== undefined) {
    const widthOfGraphImage = isFullWidth ? 9.8 : 7;
    slide.addImage({
      data: `image/png;base64,${graphImage}`,
      y: 0.97,
      w: widthOfGraphImage,
      h: 4.5,
      x: 0.1,
    });
  }

  // For graph slides with subtext
  let arrGraphSubtext = [];
  if (wastePerCoverSummary) {
    const {
      weightPerCoverForFinalMonth,
      reductionInWeightPerCoverInPercentageForFinalMonth,
      reductionInWeightPerCoverInPercentageForFinalMonthWithPreviousMonth,
    } = wastePerCoverSummary;
    if (headerText === 'LEFTOVER PER COVER (BY MONTHS)') {
      let increaseOrReductionString = '';
      if (
        typeof reductionInWeightPerCoverInPercentageForFinalMonthWithPreviousMonth === 'number' &&
        reductionInWeightPerCoverInPercentageForFinalMonthWithPreviousMonth <= 0
      ) {
        increaseOrReductionString = ' reduction';
      } else {
        increaseOrReductionString = ' increase';
      }
      arrGraphSubtext.push('Leftover per cover for the month');
      arrGraphSubtext.push(`${formatDataString(weightPerCoverForFinalMonth!, 'grams')}`);
      arrGraphSubtext.push('As compared to last month');
      arrGraphSubtext.push(
        `${formatDataString(
          reductionInWeightPerCoverInPercentageForFinalMonthWithPreviousMonth!,
          '%',
          {
            isAbsolute: true,
            hasNoSpacing: true,
          }
        )} ${increaseOrReductionString}`
      );
    } else if (headerText === 'LEFTOVER PER COVER (BY LAST 8 WEEKS)') {
      let increaseOrReductionString = '';
      if (
        typeof reductionInWeightPerCoverInPercentageForFinalMonth === 'number' &&
        reductionInWeightPerCoverInPercentageForFinalMonth <= 0
      ) {
        increaseOrReductionString = ' reduction';
      } else {
        increaseOrReductionString = ' increase';
      }
      arrGraphSubtext.push('Leftover per cover for the month');
      arrGraphSubtext.push(`${formatDataString(weightPerCoverForFinalMonth!, 'grams')}`);
      arrGraphSubtext.push('As compared to baseline');
      arrGraphSubtext.push(
        `${formatDataString(reductionInWeightPerCoverInPercentageForFinalMonth!, '%', {
          isAbsolute: true,
          hasNoSpacing: true,
        })} ${increaseOrReductionString}`
      );
    } else if (headerText === 'LEFTOVER IN TERMS OF COST (BY MONTHS)') {
      slide.addText(
        [
          { text: 'Note:' },
          {
            text: 'Data shown here is based on the menu items that have been mapped.',
            options: { bullet: true },
          },
          {
            text: 'Cost may change as more menu items are mapped and validated.',
            options: { bullet: true },
          },
        ],
        {
          fontFace: 'Dosis',
          fontSize: 8,
          color: '#102547',
          x: 7.7,
          y: 4.6,
          w: 2.2,
          h: 1,
        }
      );
    }
  }

  if (arrGraphSubtext.length > 0) {
    const arrTextProps: Array<Pptxgen.TextProps> = [];
    for (let subtextIndex = 0; subtextIndex < arrGraphSubtext.length; subtextIndex += 2) {
      const textHeader = arrGraphSubtext[subtextIndex];
      arrTextProps.push({
        text: textHeader,
        options: {
          fontSize: 14,
          color: '#102547',
          align: 'center',
          breakLine: true,
          fontFace: 'Dosis',
          bold: true,
        },
      });
      const textBody = arrGraphSubtext[subtextIndex + 1];
      arrTextProps.push({
        text: `${textBody}\n\n`,
        options: {
          fontSize: 14,
          color: '#e4745e',
          align: 'center',
          fontFace: 'Dosis',
          bold: true,
        },
      });
    }
    slide.addText(arrTextProps, { x: 7.3, y: 2, w: 2.5, h: 3 });
  }
};

/**
 * This function inserts waste images with text overlay to the current working slide
 * @param pres - Variable referencing the current working presentation. This is required to add a new slide, required for
 * waste images exceeding 3 rows
 * @param slide - Variable referencing the current working slide
 * @param arrTopMenuItemWasteAnalysis - Array of top menu items with their waste values and waste images
 * @param headerText - Header's text to be used when adding new slides
 * @param currency - Currency
 * @param numberOfImagesToDisplay - Number of image to be displayed. There is a different requirement for 'plate waste' service and 'non-plate waste' service.
 * @param isWeight - Indicates if the label on the image should display the weight or the cost
 */
const insertImage = (
  pres: Pptxgen,
  slide: Pptxgen.Slide,
  arrTopMenuItemWasteAnalysis: Array<MenuItemWasteAnalysis>,
  headerText: string,
  currency: string,
  numberOfImagesToDisplay: number,
  isWeight: boolean
) => {
  let currentSlide = slide;
  for (
    let imageRowIndex = 0;
    imageRowIndex < arrTopMenuItemWasteAnalysis.length;
    imageRowIndex += 1
  ) {
    let yValue = imageRowIndex % 3;
    const { menuItemName, weightInKilogram, numberOfThrows, cost, arrWasteImageDetail } =
      arrTopMenuItemWasteAnalysis[imageRowIndex];
    currentSlide.addText(
      `${menuItemName}\n${formatDataString(weightInKilogram, 'KG', {
        isRound2Dp: true,
      })}\n${formatDataString(cost, currency, {
        isRoundWholeNumber: true,
      })}\n${numberOfThrows} throws`,
      {
        bold: true,
        fontFace: 'Dosis',
        color: '#e4745e',
        fontSize: 12,
        w: 2,
        h: 1.3,
        x: 0.2,
        y: 1.1 + yValue * 1.5,
        align: 'center',
      }
    );
    const arrWasteImageDetailToDisplay = arrWasteImageDetail.slice(0, numberOfImagesToDisplay);
    let xValue = 0;
    for (
      let imageColumnIndex = 0;
      imageColumnIndex < arrWasteImageDetailToDisplay.length;
      imageColumnIndex += 1
    ) {
      const {
        signedUrl,
        date: thrownDate,
        weightInKilogram: imageWeight,
        cost: imageCost,
      } = arrWasteImageDetailToDisplay[imageColumnIndex];
      if (signedUrl) {
        currentSlide.addImage({
          path: signedUrl,
          x: 2.3 + xValue * 1.8,
          y: 1 + yValue * 1.5,
          h: 1.4,
          w: 1.7,
        });
      }
      const formattedThrownDate = formatDateToDDMMM(thrownDate);
      let imageText = formattedThrownDate;
      if (isWeight) {
        imageText += ` ${imageWeight.toFixed(2)} KG`;
      } else {
        imageText += ` ${currency} ${Math.round(imageCost).toLocaleString('en-us')}`;
      }
      currentSlide.addText(imageText, {
        fontFace: 'Dosis',
        color: '#FFFFFF',
        fontSize: 10,
        align: 'center',
        y: 2.1 + yValue * 1.5,
        x: 2.5 + xValue * 1.8,
        h: 0.3,
        w: 1.3,
      });
      // This is to cater for plate waste where it has 12 images to be displayed
      if ((imageColumnIndex + 1) % 4 === 0) {
        yValue += 1;
        xValue = 0;
      } else {
        xValue += 1;
      }
    }
    if (imageRowIndex === 2) {
      currentSlide = initialiseSlide(pres, SLIDE_STYLE.plain, headerText);
    }
  }
};

/**
 * This function inserts station graph to the current working slide
 * Station graphs are slightly thinner in width compared to other graphs
 * @param slide - Variable referencing the current working slide
 * @param graphImage - Base 64 string of the graph image. Undefined if there is no station data.
 */
const insertStationGraph = (slide: Pptxgen.Slide, graphImage?: string) => {
  if (graphImage !== undefined) {
    slide.addImage({ data: `image/png;base64,${graphImage}`, y: 0.97, w: 5.5, h: 4.5, x: 0.1 });
  }
};

/**
 * This function inserts 3 rows x 2 columns of waste images for each station slide
 * Additional text is placed to the left of each row giving the total weight or cost for the station
 * @param slide - Variable referencing the current working slide
 * @param arrMenuItemDetailByStation - Array of menu item detail for each station
 */
const insertStationImage = (
  slide: Pptxgen.Slide,
  arrStationWasteAnalysis: Array<StationWasteAnalysis>,
  currency?: string
) => {
  slide.addText('Top Two Items Per Station', {
    fontFace: 'Dosis',
    fontSize: 10,
    align: 'center',
    bold: true,
    x: 7.23,
    y: 0.6,
    w: 2,
    h: 0.22,
  });
  arrStationWasteAnalysis.forEach((stationWasteAnalysis, stationIndex) => {
    const {
      station,
      weightInKilogram: stationWeight,
      cost: stationCost,
      arrMenuItemWasteAnalysis,
    } = stationWasteAnalysis;
    let stationText = station;
    if (currency) {
      stationText += `\n${formatDataString(stationCost, currency, {
        isRoundWholeNumber: true,
        isPrefix: true,
      })}`;
    } else {
      stationText += `\n${formatDataString(stationWeight, 'KG', { isRoundWholeNumber: true })}`;
    }
    slide.addText(stationText, {
      fontFace: 'Dosis',
      fontSize: 10,
      align: 'center',
      bold: true,
      x: 5.65,
      y: 1.33 + stationIndex * 1.55,
      w: 1.05,
      h: 0.6,
    });

    arrMenuItemWasteAnalysis.forEach((menuItemWasteAnalysis, menuItemIndex) => {
      const {
        menuItemName,
        weightInKilogram: menuItemWeight,
        cost: menuItemCost,
        arrWasteImageDetail,
      } = menuItemWasteAnalysis;
      // The check for image is because Backend will not provide a graph image if there is no data
      if (arrWasteImageDetail[0] && arrWasteImageDetail[0].signedUrl) {
        slide.addImage({
          path: arrWasteImageDetail[0].signedUrl,
          y: 0.95 + stationIndex * 1.55,
          x: 6.7 + menuItemIndex * 1.6,
          h: 1.2,
          w: 1.45,
        });
      }
      let menuItemText = menuItemName;
      if (currency) {
        menuItemText += `\n${formatDataString(menuItemCost, currency, {
          isRoundWholeNumber: true,
          isPrefix: true,
        })}`;
      } else {
        menuItemText += `\n${formatDataString(menuItemWeight, 'KG', { isRoundWholeNumber: true })}`;
      }
      slide.addText(menuItemText, {
        fontFace: 'Dosis',
        fontSize: 6,
        align: 'center',
        x: 6.7 + menuItemIndex * 1.6,
        y: 2.12 + stationIndex * 1.55,
        w: 1.45,
        h: 0.3,
        valign: 'top',
      });
    });
  });
};

/**
 * This function formats the column explanation row on each summary table
 * Of the inner arrays, text is split by its line breaks
 * Only the first 2 lines of each cell is bolded
 * @param arrArrSummaryRowText - Array of array of text to be displayed
 * @returns Object - Pptxgen.TableRow format to be inserted as a row to a Pptxgen Table object
 */
const formatSummaryRow = (
  arrArrSummaryRowText: Array<Array<string>>,
  isOnly1ColumnSpan?: true
): Pptxgen.TableRow => {
  return arrArrSummaryRowText.map((arrCellText, arrCellTextIndex) => {
    if (arrCellTextIndex === 0)
      return {
        text: arrCellText[0],
        options: {
          bold: true,
          valign: 'top',
          colspan: isOnly1ColumnSpan ? 1 : 2,
        },
      };
    return {
      text: arrCellText.map((cellText, cellTextIndex) => {
        const cell: { text: string; options?: { bold: boolean } } = {
          text: cellText,
        };
        if (cellTextIndex < 2) {
          cell.options = {
            bold: true,
          };
        }
        return cell;
      }),
      options: {
        valign: 'top',
      },
    };
  });
};

/**
 * This function formats the calculation explanation row on each summary table
 * Of the inner arrays, text is split by its line breaks
 * Only the first column is to be bolded
 * @param arrArrCalculationRowText - Array of array of text to be displayed
 * @returns Object - Pptxgen.TableRow format to be appended as a row to a Pptxgen Table object
 */
const formatCalculationRow = (arrArrCalculationRowText: Array<Array<string>>): Pptxgen.TableRow => {
  return arrArrCalculationRowText.map((arrCellText, arrCellTextIndex) => {
    if (arrCellTextIndex === 0)
      return {
        text: arrCellText[0],
        options: {
          bold: true,
          colspan: 2,
        },
      };
    return {
      text: arrCellText.map((cellText) => ({
        text: cellText,
      })),
    };
  });
};

/**
 * Format the dateTime string into DD MMM format
 * @param dateTimeString - dateTime string
 * @returns formattedDate - Formatted date in the format of DD MMM
 */
const formatDateToDDMMM = (dateTimeString: string) => {
  const dateTime = new Date(dateTimeString.slice(0, 10));
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    weekday: 'short',
  }).format(dateTime);
  return formattedDate;
};

/**
 * This function formats the data value by prepending or appending the unit passed in
 * @param data - Data value requiring unit to be prepended or appended
 * @param unit - Unit to be prepended or appended
 * @param options - Options object
 * @param options.hasNoSpacing - Indicates if there should be a space between the data and the unit
 * @param options.isAbsolute - Indicates if the number is to be in absolute form
 * @param options.isPrefix - Indicates if the unit is to be prepended or appended
 * @param options.isRoundWholeNumber - Indicates if the number is to rounded to the whole number
 * @param options.isRound2Dp - Indicates if the number is to be rounded to 2 dp
 * @returns String of data with unit to be displayed
 */
const formatDataString = (
  data: number | string,
  unit: string,
  options?: {
    hasNoSpacing?: boolean;
    isAbsolute?: boolean;
    isPrefix?: boolean;
    isRoundWholeNumber?: boolean;
    isRound2Dp?: boolean;
  }
) => {
  const { hasNoSpacing, isAbsolute, isPrefix, isRoundWholeNumber, isRound2Dp } = options || {};
  let formattedData = data;
  if (typeof data !== 'string') {
    if (isAbsolute) {
      formattedData = Math.abs(data);
    }
    if (isRoundWholeNumber) {
      formattedData = Math.round(data);
    } else if (isRound2Dp) {
      formattedData = data.toFixed(2);
    }
  }
  const spacing = hasNoSpacing ? '' : ' ';
  let prefix = '';
  let suffix = '';
  if (isPrefix) {
    prefix = unit + spacing;
  } else {
    suffix = spacing + unit;
  }
  return `${prefix}${formattedData.toLocaleString('en-us')}${suffix}`;
};

/**
 * This function formats summary table row cells based on data values
 * Only third column and above will have its cell fill colored with green if positive, red if negative and transparent if neither
 * @param arrDataRow - Array of numbers to be displayed as a row in summary table
 * @param unit - Unit to be prepended or appended to value (e.g. 'KG', 'USD', 'grams per cover', etc.)
 * @param summaryTableRowType - 'weight', 'waste per cover' or 'cost' as they have different requirements
 * for their table display
 * @param skipFirstCol - Used for 'waste per cover' table where its first col for its second row is skipped to
 * due to merging of the first and second rows of the table displaying baseline waste per cover
 * @returns Object - Pptxgen.TableRow format to be appended as a row to a Pptxgen Table object
 */
const formatSummaryTableRow = (
  arrDataRow: Array<number | '-'>,
  unit: string,
  summaryTableRowType: string,
  skipFirstCol?: boolean
): Pptxgen.TableRow => {
  const isPrefix = summaryTableRowType === 'cost';
  const arrDataRowCell: Pptxgen.TableCell[] = [];
  arrDataRow.slice(0, 2).forEach((cellData, cellIndex) => {
    // First and Second Column
    if (skipFirstCol && cellIndex === 0) {
      return;
    }
    const dataRowCell = {
      text: `${formatDataString(cellData, unit, { isPrefix })}`,
      options: {
        rowspan: summaryTableRowType === 'waste per cover' && cellIndex === 0 ? 2 : 1,
        color: '#e4745e',
        bold: true,
      },
    };
    arrDataRowCell.push(dataRowCell);
  });

  // Third Column: Reduction value
  const reductionValue = arrDataRow[2];
  const reductionPercentage = arrDataRow[3];
  let increaseOrReductionString = '';
  if (typeof reductionValue === 'number' && reductionValue < 0) {
    increaseOrReductionString = ' reduction';
  } else if (typeof reductionValue === 'number' && reductionValue > 0) {
    increaseOrReductionString = ' increase';
  }
  arrDataRowCell.push({
    text: `${formatDataString(reductionValue, unit, {
      isAbsolute: true,
      isPrefix,
    })} (${formatDataString(reductionPercentage, '%', {
      isAbsolute: true,
    })})${increaseOrReductionString}`, // JW: check
    options: {
      color: '#e4745e',
      bold: true,
      fill:
        reductionValue === '-' || reductionPercentage === '-' || reductionValue === 0
          ? undefined
          : reductionValue < 0
          ? { color: '#d9ead3' }
          : {
              color: '#f5cbcc',
            },
    },
  });
  // Fourth Column & Fifth Column: For weight summary only. Check if the value exists before adding the cell for these 2 columns.
  // Fourth Column: (Weight summary only) Number of meals saved
  const numberOfMealsSaved = arrDataRow[4];
  const co2EmissionsAvoided = arrDataRow[5];
  if (numberOfMealsSaved) {
    arrDataRowCell.push({
      text: formatDataString(numberOfMealsSaved, ''),
      options: {
        color: '#e4745e',
        bold: true,
        fill:
          typeof numberOfMealsSaved === 'number' && numberOfMealsSaved > 0
            ? { color: '#d9ead3' }
            : typeof numberOfMealsSaved === 'number' && numberOfMealsSaved < 0
            ? { color: '#f5cbcc' }
            : undefined,
      },
    });

    // Fifth Column: (Weight summary only) CO2 Emissions Avoided
    arrDataRowCell.push({
      text: formatDataString(co2EmissionsAvoided, unit),
      options: {
        color: '#e4745e',
        bold: true,
        fill:
          typeof co2EmissionsAvoided === 'number' && co2EmissionsAvoided > 0
            ? { color: '#d9ead3' }
            : typeof co2EmissionsAvoided === 'number' && co2EmissionsAvoided < 0
            ? { color: '#f5cbcc' }
            : undefined,
      },
    });
  }
  return arrDataRowCell;
};

/**
 * This function inserts the waste summary tables to the current working slide
 * For all slides, headers are values without intervention, actual values and reduction values
 * For weight slides, there are two additional headers for number of meals saved and carbon emissions avoided
 * 2 rows will be created per service representing current month and since baseline started, with additional 2 rows appended
 * at the back for total values.
 * Note: All values represented on the table are calculated based on display value (not actual value, and done in other microservice)
 * for ease of calculation by clients.
 * @param pres - Variable referencing the current working presentation. This is required to add a new slide
 * required for exceeding 4 services per slide
 * @param slide - Variable referencing the current working slide
 * @param headerText - Header text used for discriminating the type and content to be placed in the tables
 * @param tableData - Object containing values required to populate table values
 * @param tableData.currency - Currency to be prefixed in cost values
 * @param tableData.arrReductionInWeightSummary - Array of reductionInWeightSummary for grouped service/service (excluding production service). The total values are found in the last element of the array.
 * @param tableData.arrWastePerCoverSummary - Array of wastePerCoverSummary for grouped service/service (excluding production service). The total values are found in the last element of the array.
 * @param tableData.arrReductionInCostSummary - Array of reductionInCostSummary for grouped service/service (excluding production service). The total values are found in the last element of the array.
 * @param isAllServiceFromSameGroup - Indicates if all the service belong to the same group. Only used for generating SUMMARY - TOTAL LEFTOVER PER COVER table.
 */
const insertTable = (
  pres: Pptxgen,
  slide: Pptxgen.Slide,
  headerText: string,
  tableData: {
    currency: string;
    arrReductionInWeightSummary: ReductionInWeightSummary[];
    arrWastePerCoverSummary: WastePerCoverSummary[];
    arrReductionInCostSummary: ReductionInCostSummary[];
  }
) => {
  let arrPPTTableRow: Array<Pptxgen.TableRow> = [];
  const NUMBER_OF_SERVICE_PER_SLIDE = 4;
  const tableProp: Pptxgen.TableProps = {
    x: 0.125,
    y: 0.85,
    w: 9.75,
    h: 1,
    fontFace: 'Dosis',
    fontSize: 8,
    align: 'center',
    border: { type: 'solid', pt: 1, color: '#102547' },
    color: '#102547',
    valign: 'middle',
  };
  const {
    currency,
    arrReductionInWeightSummary,
    arrWastePerCoverSummary,
    arrReductionInCostSummary,
  } = tableData;
  if (
    headerText === 'SUMMARY - TOTAL LEFTOVER IN TERMS OF WEIGHT' ||
    headerText === 'SUMMARY - TOTAL LEFTOVER IN TERMS OF WEIGHT (GROUPED)'
  ) {
    arrPPTTableRow.push(formatSummaryRow(ARR_ARR_TOTAL_WEIGHT_SUMMARY_ROW_TEXT));
    arrPPTTableRow.push(formatCalculationRow(ARR_ARR_TOTAL_WEIGHT_CALCULATION_ROW_TEXT));
    arrReductionInWeightSummary.forEach(
      (reductionInWeightSummary, reductionInWeightSummaryIndex) => {
        const {
          name,
          totalWeightWithoutReductionForFinalMonth,
          totalWeightForFinalMonth,
          totalWeightWithoutReductionForSinceBaselineStarted,
          totalWeightForSinceBaselineStarted,
          reductionInWeightInValueForFinalMonth,
          reductionInWeightInPercentageForFinalMonth,
          numberOfMealsSavedForFinalMonth,
          amountOfCO2EmissionsAvoidedForFinalMonth,
          reductionInWeightInValueForSinceBaselineStarted,
          reductionInWeightInPercentageForSinceBaselineStarted,
          numberOfMealsSavedForSinceBaselineStarted,
          amountOfCO2EmissionsAvoidedForSinceBaselineStarted,
        } = reductionInWeightSummary;
        const arrReductionInWeightValueForServiceForTheMonthText = formatSummaryTableRow(
          [
            totalWeightWithoutReductionForFinalMonth,
            totalWeightForFinalMonth,
            reductionInWeightInValueForFinalMonth,
            reductionInWeightInPercentageForFinalMonth,
            numberOfMealsSavedForFinalMonth,
            amountOfCO2EmissionsAvoidedForFinalMonth,
          ],
          'KG',
          'weight'
        );
        arrPPTTableRow.push([
          { text: name, options: { rowspan: 2, bold: true } },
          { text: 'For The Month', options: { bold: true } },
          ...arrReductionInWeightValueForServiceForTheMonthText,
        ]);
        const arrReductionInWeightValueForServiceSinceBaselineStartedText = formatSummaryTableRow(
          [
            totalWeightWithoutReductionForSinceBaselineStarted,
            totalWeightForSinceBaselineStarted,
            reductionInWeightInValueForSinceBaselineStarted,
            reductionInWeightInPercentageForSinceBaselineStarted,
            numberOfMealsSavedForSinceBaselineStarted,
            amountOfCO2EmissionsAvoidedForSinceBaselineStarted,
          ],
          'KG',
          'weight'
        );
        arrPPTTableRow.push([
          { text: 'Since Baseline Started', options: { bold: true } },
          ...arrReductionInWeightValueForServiceSinceBaselineStartedText,
        ]);
        if (
          (reductionInWeightSummaryIndex + 1) % NUMBER_OF_SERVICE_PER_SLIDE === 0 &&
          reductionInWeightSummaryIndex + 1 !== arrReductionInWeightSummary.length
        ) {
          slide.addTable(arrPPTTableRow, tableProp);
          slide = initialiseSlide(pres, 'plain', headerText);
          arrPPTTableRow = [];
          arrPPTTableRow.push(formatSummaryRow(ARR_ARR_TOTAL_WEIGHT_SUMMARY_ROW_TEXT));
          arrPPTTableRow.push(formatCalculationRow(ARR_ARR_TOTAL_WEIGHT_CALCULATION_ROW_TEXT));
        }
      }
    );
    slide.addTable(arrPPTTableRow, tableProp);
  } else if (
    headerText === 'SUMMARY - TOTAL LEFTOVER PER COVER' ||
    headerText === 'SUMMARY - TOTAL LEFTOVER PER COVER (GROUPED)'
  ) {
    arrPPTTableRow.push(formatSummaryRow(ARR_ARR_TOTAL_LEFTOVER_PER_COVER_SUMMARY_ROW_TEXT));
    arrPPTTableRow.push(
      formatCalculationRow(ARR_ARR_TOTAL_LEFTOVER_PER_COVER_CALCULATION_ROW_TEXT)
    );
    arrWastePerCoverSummary.forEach((wastePerCoverSummary, wastePerCoverSummaryIndex) => {
      const {
        name,
        weightPerCoverForBaseline,
        weightPerCoverForFinalMonth,
        weightPerCoverForSinceBaselineStarted,
        reductionInWeightPerCoverInValueForFinalMonth,
        reductionInWeightPerCoverInPercentageForFinalMonth,
        reductionInWeightPerCoverInValueForSinceBaselineStarted,
        reductionInWeightPerCoverInPercentageForSinceBaselineStarted,
      } = wastePerCoverSummary;
      const arrReductionInWeightPerCoverValueForServiceForTheMonthText = formatSummaryTableRow(
        [
          weightPerCoverForBaseline,
          weightPerCoverForFinalMonth,
          reductionInWeightPerCoverInValueForFinalMonth,
          reductionInWeightPerCoverInPercentageForFinalMonth,
        ],
        'grams per cover',
        'waste per cover'
      );
      arrPPTTableRow.push([
        { text: name, options: { rowspan: 2, bold: true } },
        { text: 'For The Month', options: { bold: true } },
        ...arrReductionInWeightPerCoverValueForServiceForTheMonthText,
      ]);
      const arrReductionInWeightPerCoverValueForServiceSinceBaselineStartedText =
        formatSummaryTableRow(
          [
            weightPerCoverForBaseline,
            weightPerCoverForSinceBaselineStarted,
            reductionInWeightPerCoverInValueForSinceBaselineStarted,
            reductionInWeightPerCoverInPercentageForSinceBaselineStarted,
          ],
          'grams per cover',
          'waste per cover',
          true
        );
      arrPPTTableRow.push([
        { text: 'Since Baseline Started', options: { bold: true } },
        ...arrReductionInWeightPerCoverValueForServiceSinceBaselineStartedText,
      ]);
      if (
        (wastePerCoverSummaryIndex + 1) % NUMBER_OF_SERVICE_PER_SLIDE === 0 &&
        wastePerCoverSummaryIndex + 1 !== arrWastePerCoverSummary.length
      ) {
        slide.addTable(arrPPTTableRow, tableProp);
        slide = initialiseSlide(pres, 'plain', headerText);
        arrPPTTableRow = [];
        arrPPTTableRow.push(formatSummaryRow(ARR_ARR_TOTAL_LEFTOVER_PER_COVER_SUMMARY_ROW_TEXT));
        arrPPTTableRow.push(
          formatCalculationRow(ARR_ARR_TOTAL_LEFTOVER_PER_COVER_CALCULATION_ROW_TEXT)
        );
      }
    });
    slide.addImage({
      path: 'lightbulb.png',
      x: 2.2,
      y: 4.98,
      h: 0.5,
      w: 0.5,
    });
    slide.addText(
      [
        {
          text: 'Reduction in leftovers per cover (grams) is the key.',
          options: { bullet: true },
        },
        {
          text: 'Based on our historical data, hotels can achieve 20-30% food waste reduction in a year by consistently managing food waste and taking corrective actions.',
          options: { bullet: true },
        },
      ],
      {
        x: 2.8,
        y: 4.98,
        h: 0.6,
        w: 5,
        bold: true,
        fontFace: 'Dosis',
        fontSize: 8,
        color: '#102547',
      }
    );
    slide.addTable(arrPPTTableRow, tableProp);
  } else if (
    headerText === 'SUMMARY - TOTAL LEFTOVER IN TERMS OF COST' ||
    headerText === 'SUMMARY - TOTAL LEFTOVER IN TERMS OF COST (GROUPED)'
  ) {
    arrPPTTableRow.push(formatSummaryRow(ARR_ARR_TOTAL_COST_SUMMARY_ROW_TEXT(currency)));
    arrPPTTableRow.push(formatCalculationRow(ARR_ARR_TOTAL_COST_CALCULATION_ROW_TEXT));
    arrReductionInCostSummary.forEach((reductionInCostSummary, reductionInCostSummaryIndex) => {
      const {
        name,
        totalCostWithoutReductionForFinalMonth,
        totalCostForFinalMonth,
        totalCostWithoutReductionForSinceBaselineStarted,
        totalCostForSinceBaselineStarted,
        reductionInCostInValueForFinalMonth,
        reductionInCostInPercentageForFinalMonth,
        reductionInCostInValueForSinceBaselineStarted,
        reductionInCostInPercentageForSinceBaselineStarted,
      } = reductionInCostSummary;
      const arrReductionInCostValueForServiceForTheMonthText = formatSummaryTableRow(
        [
          totalCostWithoutReductionForFinalMonth,
          totalCostForFinalMonth,
          reductionInCostInValueForFinalMonth,
          reductionInCostInPercentageForFinalMonth,
        ],
        currency,
        'cost'
      );
      arrPPTTableRow.push([
        { text: name, options: { rowspan: 2, bold: true } },
        { text: 'For The Month', options: { bold: true } },
        ...arrReductionInCostValueForServiceForTheMonthText,
      ]);
      const arrReductionInCostValueForServiceSinceBaselineStartedText = formatSummaryTableRow(
        [
          totalCostWithoutReductionForSinceBaselineStarted,
          totalCostForSinceBaselineStarted,
          reductionInCostInValueForSinceBaselineStarted,
          reductionInCostInPercentageForSinceBaselineStarted,
        ],
        currency,
        'cost'
      );
      arrPPTTableRow.push([
        { text: 'Since Baseline Started', options: { bold: true } },
        ...arrReductionInCostValueForServiceSinceBaselineStartedText,
      ]);
      if (
        (reductionInCostSummaryIndex + 1) % NUMBER_OF_SERVICE_PER_SLIDE === 0 &&
        reductionInCostSummaryIndex + 1 !== arrReductionInCostSummary.length
      ) {
        slide.addTable(arrPPTTableRow, tableProp);
        slide = initialiseSlide(pres, 'plain', headerText);
        arrPPTTableRow = [];
        arrPPTTableRow.push(formatSummaryRow(ARR_ARR_TOTAL_COST_SUMMARY_ROW_TEXT(currency)));
        arrPPTTableRow.push(formatCalculationRow(ARR_ARR_TOTAL_COST_CALCULATION_ROW_TEXT));
      }
    });
    slide.addText(
      [
        { text: 'Note:' },
        {
          text: 'Data shown here is based on the menu items that have been mapped.',
          options: { bullet: true },
        },
        {
          text: 'Cost may change as more menu items are mapped and validated.',
          options: { bullet: true },
        },
      ],
      {
        fontFace: 'Dosis',
        fontSize: 10,
        color: '#102547',
        x: 0.5,
        y: 4.72,
        w: 6,
        h: 0.7,
      }
    );
    slide.addTable(arrPPTTableRow, tableProp);
  }
};

/**
 * This function appends the ordinal suffix to a number (e.g. 1 -> 1st, 2 -> 2nd, etc.)
 * @param number - Number to be suffixed
 * @returns - Number with ordinal suffix
 */
const appendOrdinalSuffixToNumber = (number: number) => {
  var lastDigit = number % 10;
  if (lastDigit === 1) {
    return number + 'st';
  }
  if (lastDigit === 2) {
    return number + 'nd';
  }
  if (lastDigit === 3) {
    return number + 'rd';
  }
  return number + 'th';
};

/**
 * This function inserts the service's top 6 menu item table to the current working slide
 * The table headers include 'Top 6 High Volume Items'/'Top 6 High Value Items', 'This Month' and 'Last Month'
 * Comparison between 'This Month' and 'Last Month' menu item trend is done and increases are highlighted in red,
 * while decreases are highlighted in green for 'This Month'. If there is no change, they are highlighted in yellow.
 * @param slide - Variable referencing the current working slide
 * @param tableHeaderText - Table header 'Top 6 High Volume Items' or 'Top 6 High Value Items'
 * @param currency - Currency to be prefixed in cost values
 * @param arrTop6MenuItem - Array of menu items sorted in order of display
 * @param arrTop6MenuItemForPreviousMonth - Array of menu items from previous period sorted in order of display
 * @param options - Options object
 * @param options.isProductionService - Indicate if this table belongs to a production service. It is used to disable the highlighting of cells.
 * @param options.isWeight - Indicate if this table is created for weight or cost
 */
const insertServiceTop6MenuItemTable = (
  slide: Pptxgen.Slide,
  tableHeaderText: string,
  currency: string,
  arrTop6MenuItem: Array<MenuItemWasteAnalysis>,
  arrTop6MenuItemForPreviousMonth: Array<MenuItemWasteAnalysis>,
  options: {
    isProductionService: boolean;
    isWeight: boolean;
  }
) => {
  const { isProductionService, isWeight } = options;
  // Trend comparison
  const mapMenuItemSummaryByMenuItemName = new Map(
    arrTop6MenuItem.map((topMenuItem) => {
      const { menuItemName } = topMenuItem;
      return [menuItemName, topMenuItem];
    })
  );
  const comparisonKey =
    tableHeaderText === 'Top 6 High Volume Items' ? 'weightPerCover' : 'costPerCover';
  const mapTrendByMenuItemName = new Map();
  if (!isProductionService) {
    arrTop6MenuItemForPreviousMonth.forEach((menuItemSummaryForPreviousMonth) => {
      const { menuItemName } = menuItemSummaryForPreviousMonth;
      const menuItemSummary = mapMenuItemSummaryByMenuItemName.get(menuItemName);
      if (menuItemSummary) {
        let trend = 'unchanged';
        const valueDifference =
          Number(Number(menuItemSummary[comparisonKey]).toFixed(2)) -
          Number(Number(menuItemSummaryForPreviousMonth[comparisonKey]).toFixed(2));
        if (valueDifference < 0) {
          trend = 'decrease';
        } else if (valueDifference > 0) {
          trend = 'increase';
        }
        mapTrendByMenuItemName.set(menuItemName, trend);
      }
    });
  }

  const arrPPTTableRow: Array<Pptxgen.TableRow> = [];
  arrPPTTableRow.push([
    { text: tableHeaderText, options: { bold: true } },
    { text: 'This Month', options: { bold: true } },
    { text: 'Last Month', options: { bold: true } },
  ]);
  for (
    let menuItemIndex = 0;
    menuItemIndex < Math.max(arrTop6MenuItem.length, arrTop6MenuItemForPreviousMonth.length);
    menuItemIndex += 1
  ) {
    const menuItem = arrTop6MenuItem[menuItemIndex];
    const menuItemForPreviousMonth = arrTop6MenuItemForPreviousMonth[menuItemIndex];

    const currentPPTTableRow: Pptxgen.TableRow = [
      { text: appendOrdinalSuffixToNumber(menuItemIndex + 1), options: { bold: true } },
    ];
    if (menuItem) {
      const { menuItemName, weightInKilogram, weightPerCover, cost, numberOfThrows, costPerCover } =
        menuItem;
      let fill;
      const trend = mapTrendByMenuItemName.get(menuItemName);
      if (trend === 'increase') {
        fill = { color: '#f4cccc' };
      } else if (trend === 'decrease') {
        fill = { color: '#d9ead3' };
      } else if (trend === 'unchanged') {
        fill = { color: '#fff2cc' };
      }
      currentPPTTableRow.push({
        text: `${menuItemName}\n${formatDataString(weightInKilogram, 'KG', { isRound2Dp: true })} ${
          isWeight
            ? `(${formatDataString(weightPerCover, 'grams per cover', {
                isRound2Dp: true,
              })})`
            : ''
        }\n${formatDataString(cost, currency, {
          isRoundWholeNumber: true,
          isPrefix: true,
        })}${
          !isWeight
            ? ` (${
                typeof costPerCover === 'number'
                  ? `${currency} ${convertToAbbreviatedNumber(costPerCover)}` // JW: to do to combine
                  : costPerCover
              } per cover)`
            : ''
        }\n${numberOfThrows} throws`,
        options: {
          fill,
        },
      });
    } else {
      currentPPTTableRow.push({
        text: '',
      });
    }
    if (menuItemForPreviousMonth) {
      const { menuItemName, weightInKilogram, weightPerCover, cost, numberOfThrows, costPerCover } =
        menuItemForPreviousMonth;
      currentPPTTableRow.push({
        text: `${menuItemName}\n${formatDataString(weightInKilogram, 'KG', {
          isRound2Dp: true,
        })} ${
          isWeight
            ? `(${formatDataString(weightPerCover, 'grams per cover', {
                isRound2Dp: true,
              })})`
            : ''
        }\n${formatDataString(cost, currency, {
          isRoundWholeNumber: true,
          isPrefix: true,
        })}${
          !isWeight
            ? ` (${
                typeof costPerCover === 'number'
                  ? `${currency} ${convertToAbbreviatedNumber(costPerCover)}`
                  : costPerCover
              } per cover)`
            : ''
        }\n${numberOfThrows} throws`,
      });
    } else {
      currentPPTTableRow.push({
        text: '',
      });
    }
    arrPPTTableRow.push(currentPPTTableRow);
  }
  slide.addTable(arrPPTTableRow, {
    x: 0.5,
    y: 1,
    w: 9,
    h: 1,
    fontFace: 'Dosis',
    fontSize: 8,
    align: 'center',
    border: { type: 'solid', pt: 1, color: '#102547' },
    color: '#102547',
    valign: 'middle',
  });
};

/**
 * This function inserts graph images to the current working slide for baseline report
 * @param slide - Variable referencing the current working slide
 * @param graphImage - Base 64 string of the graph image
 * @param wasteValue - Waste per cover values required for graph subtexts
 * @param options - Options object
 * @param options.isWeight - Indicates if the graph is for weight
 * @param options.isFullWidth - Indicates if the graph is to fill up the entire width of the slide
 */
const insertGraphForBaseline = (
  slide: Pptxgen.Slide,
  graphImage: string,
  wasteValue?: number | string,
  options?: {
    isWeight?: boolean;
    isFullWidth?: boolean;
  }
) => {
  if (graphImage !== undefined) {
    const widthOfGraphImage = options?.isFullWidth ? 9.8 : 7;
    slide.addImage({
      data: `image/png;base64,${graphImage}`,
      y: 0.97,
      w: widthOfGraphImage,
      h: 4.5,
      x: 0.1,
    });
  }

  if (wasteValue !== null && wasteValue !== undefined) {
    const summaryText = options?.isWeight ? 'Total Weight' : 'Total LPC';
    const unit = options?.isWeight ? 'KG' : 'grams';
    slide.addText(
      [
        { text: summaryText },
        { text: `${wasteValue} ${unit}`, options: { softBreakBefore: true } },
      ],
      {
        fontFace: 'Dosis',
        fontSize: 8,
        bold: true,
        color: '#e4745e',
        x: 9.2,
        y: 5,
        w: 0.8,
        h: 0.5,
        align: 'center',
      }
    );
  }
};

/**
 * This function inserts table for baseline report. It only presents weight per cover values
 * @param pres - Variable referencing the current working presentation. This is required to add a new slide
 * required for exceeding 4 services per slide
 * @param slide - Variable referencing the current working slide
 * @param headerText - Header text used for discriminating the type and content to be placed in the tables
 * @param arrNameAndWeightPerCover - Array of object containing name and weight per cover (name can the group name or the service name)
 */
const insertTableForBaseline = (
  pres: Pptxgen,
  slide: Pptxgen.Slide,
  headerText: string,
  arrNameAndWeightPerCover: Array<{ name: string; weightPerCover: number | string }>
) => {
  let arrPPTTableRow: Array<Pptxgen.TableRow> = [];
  const NUMBER_OF_SERVICE_PER_SLIDE = 9;
  const tableProp: Pptxgen.TableProps = {
    x: 2,
    y: 1.2,
    w: 6,
    h: 1,
    fontFace: 'Dosis',
    fontSize: 8,
    align: 'center',
    border: { type: 'solid', pt: 1, color: '#102547' },
    color: '#102547',
    valign: 'middle',
  };

  arrPPTTableRow.push(
    formatSummaryRow(ARR_ARR_TOTAL_LEFTOVER_PER_COVER_SUMMARY_ROW_TEXT.slice(0, 2), true)
  );
  arrNameAndWeightPerCover
    .slice(0, arrNameAndWeightPerCover.length - 1)
    .forEach((nameAndWeightPerCover, nameAndWeightPerCoverIndex) => {
      const { name, weightPerCover } = nameAndWeightPerCover;
      arrPPTTableRow.push([
        { text: name, options: { bold: true } },
        { text: `${weightPerCover} grams per cover`, options: { color: '#e4745e', bold: true } },
      ]);

      if (
        (nameAndWeightPerCoverIndex + 1) % NUMBER_OF_SERVICE_PER_SLIDE === 0 &&
        nameAndWeightPerCoverIndex + 1 !== arrNameAndWeightPerCover.length - 1
      ) {
        slide.addTable(arrPPTTableRow, tableProp);
        slide = initialiseSlide(pres, 'plain', headerText);
        arrPPTTableRow = [];
        arrPPTTableRow.push(
          formatSummaryRow(ARR_ARR_TOTAL_LEFTOVER_PER_COVER_SUMMARY_ROW_TEXT.slice(0, 2), true)
        );
      }
    });
  arrPPTTableRow.push([
    {
      text: arrNameAndWeightPerCover[arrNameAndWeightPerCover.length - 1].name,
      options: { bold: true, fill: { color: '#fff2cc' } },
    },
    {
      text: `${
        arrNameAndWeightPerCover[arrNameAndWeightPerCover.length - 1].weightPerCover
      } grams per cover`,
      options: { color: '#e4745e', bold: true, fill: { color: '#fff2cc' } },
    },
  ]);
  slide.addImage({
    path: 'lightbulb.png',
    x: 2.2,
    y: 4.98,
    h: 0.5,
    w: 0.5,
  });
  slide.addText(
    [
      {
        text: 'Reduction in leftovers per cover (grams) is the key.',
        options: { bullet: true },
      },
      {
        text: 'Based on our historical data, hotels can achieve 20-30% food waste reduction in a year by consistently managing food waste and taking corrective actions.',
        options: { bullet: true },
      },
    ],
    {
      x: 2.8,
      y: 4.98,
      h: 0.6,
      w: 5,
      bold: true,
      fontFace: 'Dosis',
      fontSize: 8,
      color: '#102547',
    }
  );
  slide.addTable(arrPPTTableRow, tableProp);
};

/**
 * This function inserts top waste images by weight for each service for a particular issue (each service will has its waste images displayed on one slide)
 * @param pres - Variable referencing the current working presentation. This is required to add a new slide, required for adding the specific issue for all the individual services
 * @param headerText - Header's text to be used when adding new slides. Note that this text only contain the issue type information
 * @param arrIssueWasteAnalysis - Array of issue with their waste values and waste images for all the individual services
 */
const insertIssueImageSlideForAllServices = (
  pres: Pptxgen,
  headerText: string,
  arrIssueWasteAnalysis: Array<IssueWasteAnalysis>
) => {
  arrIssueWasteAnalysis.forEach((issueWasteAnalysis) => {
    const {
      issueName,
      serviceName,
      weightInKilogram,
      numberOfThrows,
      arrTopXWasteImageSignedUrlByWeight,
    } = issueWasteAnalysis;
    let slide = initialiseSlide(
      pres,
      SLIDE_STYLE.plain,
      `${serviceName!.toUpperCase()} - ${headerText}`
    );
    let yValue = 0;
    slide.addText(
      `${issueName}\n${formatDataString(weightInKilogram!, 'KG', {
        isRound2Dp: true,
      })}\n${numberOfThrows} throws`,
      {
        bold: true,
        fontFace: 'Dosis',
        color: '#e4745e',
        fontSize: 12,
        w: 2,
        h: 1.3,
        x: 0.2,
        y: 1.1 + yValue * 1.5,
        align: 'center',
      }
    );
    let xValue = 0;
    arrTopXWasteImageSignedUrlByWeight.forEach((wasteImageSignedUrl, index) => {
      slide.addImage({
        path: wasteImageSignedUrl,
        x: 2.3 + xValue * 1.8,
        y: 1 + yValue * 1.5,
        h: 1.4,
        w: 1.7,
      });
      if ((index + 1) % 4 === 0) {
        yValue += 1;
        xValue = 0;
      } else {
        xValue += 1;
      }
    });
  });
};

/**
 * This function inserts waste images into a slide. Two slides will be created - one is for showing the top wasted images, and the other is
 * for showing the top 4 wasted images of the top 3 internal groupings.
 * @param pres - Variable referencing the current working presentation. This is required to add a new slide, required for
 * waste images exceeding 3 rows
 * @param headerText - Header's text to be used when adding new slides. Note that this text only contain the group/service information
 * @param arrTop3InternalGroupingDetailByWeight - Array of top internal groupings with their waste values and waste images
 * @param arrTopXWasteImageSignedUrlByWeight - Array of signed url of top waste
 * @param numberOfImagesToDisplay - Number of image to be displayed
 */
const insertImageSlidesForBaseline = (
  pres: Pptxgen,
  headerTextForServicePortion: string,
  arrTop3InternalGroupingDetailByWeight: Array<Partial<MenuItemWasteAnalysis>>,
  arrTopXWasteImageSignedUrlByWeight: Array<string>,
  numberOfImagesToDisplay: number
) => {
  let slide = initialiseSlide(
    pres,
    SLIDE_STYLE.plain,
    `${headerTextForServicePortion} - TOP WASTED THROWS`
  );
  const arrTop24ImageSignedUrl = arrTopXWasteImageSignedUrlByWeight.slice(0, 24);
  let yValue = 0;
  for (let imageIndex = 0; imageIndex < arrTop24ImageSignedUrl.length; imageIndex += 1) {
    let xValue = imageIndex % 6;
    slide.addImage({
      path: arrTop24ImageSignedUrl[imageIndex],
      x: 0.6 + xValue * 1.48,
      y: 0.85 + yValue * 1.18,
      h: 1.1,
      w: 1.34,
    });
    if (xValue === 5) {
      yValue += 1;
    }
  }

  slide = initialiseSlide(
    pres,
    SLIDE_STYLE.plain,
    `${headerTextForServicePortion} - TOP 3 LEFTOVER IN TERMS OF WEIGHT`
  );
  for (
    let imageRowIndex = 0;
    imageRowIndex < arrTop3InternalGroupingDetailByWeight.length;
    imageRowIndex += 1
  ) {
    let yValue = imageRowIndex % 3;
    const { weightInKilogram, arrWasteImageDetail } =
      arrTop3InternalGroupingDetailByWeight[imageRowIndex];
    slide.addText(
      `${formatDataString(weightInKilogram!, 'KG', {
        isRound2Dp: true,
      })}`,
      {
        bold: true,
        fontFace: 'Dosis',
        color: '#102547',
        fontSize: 12,
        w: 2,
        h: 1.3,
        x: 0.2,
        y: 1.1 + yValue * 1.5,
        align: 'center',
      }
    );
    let xValue = 0;
    const arrWasteImageDetailToDisplay = arrWasteImageDetail!.slice(0, numberOfImagesToDisplay);
    for (
      let imageColumnIndex = 0;
      imageColumnIndex < arrWasteImageDetailToDisplay.length;
      imageColumnIndex += 1
    ) {
      slide.addImage({
        path: arrWasteImageDetailToDisplay[imageColumnIndex].signedUrl,
        x: 2.3 + xValue * 1.8,
        y: 1 + yValue * 1.5,
        h: 1.4,
        w: 1.7,
      });
      xValue += 1;
    }
  }
};

/**
 * This function inserts 6 Next Steps body slides that contain information about location monthly report and Lumitics Dashboard features
 * @param pres - Variable referencing the current working presentation. This is required to add a new slide, required for
 * @param restaurantName - Restaurant name to be used in one of the paragraphs of one of the slide
 */
const insertBaselineNextStepsBodySlides = (pres: Pptxgen, restaurantName: string) => {
  const slideStyle = SLIDE_STYLE.plain;
  let slide = initialiseSlide(pres, slideStyle, 'NEXT STEPS');
  slide.addText(
    [
      { text: 'Lumitics', options: { bold: true } },
      {
        text: 'Check data collected and share in the group chat should there be any device usage issues',
        options: { bullet: true },
      },
      {
        text: 'Schedule monthly meetings to go through the past month’s data and identify opportunities for food waste reduction by highlighting the top wasted items',
        options: { bullet: true },
      },
    ],
    {
      fontFace: 'Dosis',
      fontSize: 14,
      color: '#102547',
      x: 1,
      y: 1.5,
      w: 8,
      h: 1,
    }
  );
  slide.addText(
    [
      { text: restaurantName, options: { bold: true } },
      {
        text: 'Input the daily covers on ',
        options: { bullet: true },
      },
      {
        text: 'Lumitics Dashboard',
        options: {
          breakLine: false,
          hyperlink: { url: LUMITICS_DASHBOARD_URL, tooltip: 'Visit Lumitics' },
        },
      },
      {
        text: ' - latest by the 3rd of every month',
        options: {
          breakLine: false,
        },
      },
      {
        text: 'Complete the menu item mapping on ',
        options: { bullet: true },
      },
      {
        text: 'Lumitics Dashboard',
        options: {
          breakLine: false,
          hyperlink: { url: LUMITICS_DASHBOARD_URL, tooltip: 'Visit Lumitics' },
        },
      },
    ],
    {
      fontFace: 'Dosis',
      fontSize: 14,
      color: '#102547',
      x: 1,
      y: 2.7,
      w: 8,
      h: 1,
    }
  );

  // Create sample slide for Top 3 items in terms of volume from the location monthly report
  slide = initialiseSlide(
    pres,
    slideStyle,
    'SAMPLE REPORT - TOP THREE ITEMS IN TERMS OF VOLUME (WEIGHT)'
  );
  slide.addImage({
    path: 'sampleTop3ByWeight.png',
    x: 0,
    y: 0.36,
    h: '88%',
    w: '100%',
  });

  // Create sample slide for Top 3 items in terms of value from the location monthly report
  slide = initialiseSlide(
    pres,
    slideStyle,
    'SAMPLE REPORT - TOP THREE ITEMS IN TERMS OF VALUE (COST) '
  );
  slide.addImage({
    path: 'sampleTop3ByCost.png',
    x: 0,
    y: 0.36,
    h: '88%',
    w: '100%',
  });

  // Create sample slide for Dashboard - Link
  slide = initialiseSlide(pres, slideStyle, 'DASHBOARD - LINK');
  slide.addImage({
    path: 'sampleDashboardLink.png',
    x: 2,
    y: 1.1,
    h: '65%',
    w: '64%',
  });
  slide.addText(
    [
      { text: 'Link: ', options: { bold: true } },
      {
        text: LUMITICS_DASHBOARD_URL,
        options: {
          breakLine: false,
          hyperlink: { url: LUMITICS_DASHBOARD_URL, tooltip: 'Visit Lumitics' },
        },
      },
    ],
    {
      fontFace: 'Dosis',
      fontSize: 14,
      color: '#102547',
      x: 3.5,
      y: 5,
      w: 3.5,
      h: 0.5,
    }
  );

  // Create sample slide for Dashboard - Cover Input Mapping
  slide = initialiseSlide(pres, slideStyle, 'DASHBOARD - COVER INPUT');
  slide.addImage({
    path: 'sampleDashboardCoverInput.png',
    x: 0.2,
    y: 1.4,
    h: '61%',
    w: '96%',
  });

  // Create sample slide for Dashboard - Menu Item Mapping
  slide = initialiseSlide(pres, slideStyle, 'DASHBOARD - MENU ITEM MAPPING');
  slide.addImage({
    path: 'sampleDashboardMenuItemMapping.png',
    x: 0.1,
    y: 1.3,
    h: '60%',
    w: '96%',
  });
};

/**
 * This function inserts the summary values for a location - total weight, number of meals wasted and the amount of CO2 emitted for annual
 * @param slide - Variable referencing the current working slide
 * @param weight - Total weight in KG rounded to whole number
 * @param numberOfDayWithinDateRange - Number of days that the weight is summed from
 */
const insertSummaryValues = (
  slide: Pptxgen.Slide,
  weight: number,
  numberOfDayWithinDateRange: number
) => {
  const annualWasteValue = Math.round((weight / numberOfDayWithinDateRange) * 365);
  slide.addImage({
    path: 'foodWasteIcon.png',
    x: 1.4,
    y: 1.2,
    h: 1.2,
    w: 1.2,
  });
  slide.addText(
    [
      {
        text: 'Annual food waste outlook without Lumitics',
        options: { color: '#102547' },
      },
      {
        text: ' ',
        options: {
          softBreakBefore: true,
        },
      },
      {
        text: `${annualWasteValue} KG`,
        options: { color: '#e4745e', softBreakBefore: true },
      },
      {
        text: ' ',
        options: {
          softBreakBefore: true,
        },
      },
      {
        text: `(${weight} KG / ${numberOfDayWithinDateRange} days x 365 days)`,
        options: { color: '#e4745e', softBreakBefore: true },
      },
    ],
    {
      x: 0.8,
      y: 2.8,
      h: 1.2,
      w: 2.4,
      bold: true,
      fontFace: 'Dosis',
      fontSize: 11,
      color: '#102547',
      align: 'center',
    }
  );

  const numberOfMealsWasted = Math.round(annualWasteValue * 4);
  slide.addImage({
    path: 'mealIcon.png',
    x: 4.3,
    y: 1.2,
    h: 1.2,
    w: 1.2,
  });
  slide.addText(
    [
      {
        text: 'Number of meals wasted without Lumitics',
        options: { color: '#102547' },
      },
      {
        text: ' ',
        options: {
          softBreakBefore: true,
        },
      },
      {
        text: `${numberOfMealsWasted}`,
        options: { color: '#e4745e', softBreakBefore: true },
      },
      {
        text: ' ',
        options: {
          softBreakBefore: true,
        },
      },
      {
        text: '(1 KG of food waste = 4 meals)',
        options: { color: '#e4745e', softBreakBefore: true },
      },
    ],
    {
      x: 3.7,
      y: 2.8,
      h: 1.2,
      w: 2.4,
      bold: true,
      fontFace: 'Dosis',
      fontSize: 11,
      color: '#102547',
      align: 'center',
    }
  );

  const amountOfCo2Emissions = Math.round(annualWasteValue * 2.5);
  slide.addImage({
    path: 'co2Icon.png',
    x: 7.2,
    y: 1.2,
    h: 1.2,
    w: 1.2,
  });
  slide.addText(
    [
      {
        text: 'Amount of CO2 emissions without Lumitics',
        options: { color: '#102547' },
      },
      {
        text: ' ',
        options: {
          softBreakBefore: true,
        },
      },
      {
        text: `${amountOfCo2Emissions} KG`,
        options: { color: '#e4745e', softBreakBefore: true },
      },
      {
        text: ' ',
        options: {
          softBreakBefore: true,
        },
      },
      {
        text: '(1 KG of food waste = 2.5 KG of CO2)',
        options: { color: '#e4745e', softBreakBefore: true },
      },
    ],
    {
      x: 6.6,
      y: 2.8,
      h: 1.2,
      w: 2.4,
      bold: true,
      fontFace: 'Dosis',
      fontSize: 11,
      color: '#102547',
      align: 'center',
    }
  );

  slide.addImage({
    path: 'lightbulb.png',
    x: 2.2,
    y: 4.98,
    h: 0.5,
    w: 0.5,
  });
  slide.addText(
    [
      {
        text: 'Reduction in leftovers per cover (grams) is the key.',
        options: { bullet: true },
      },
      {
        text: 'Based on our historical data, hotels can achieve 20-30% food waste reduction in a year by consistently managing food waste and taking corrective actions.',
        options: { bullet: true },
      },
    ],
    {
      x: 2.8,
      y: 4.98,
      h: 0.6,
      w: 5,
      bold: true,
      fontFace: 'Dosis',
      fontSize: 8,
      color: '#102547',
    }
  );
};

export {
  insertGraph,
  insertGraphForBaseline,
  insertImage,
  insertImageSlidesForBaseline,
  insertIssueImageSlideForAllServices,
  insertBaselineNextStepsBodySlides,
  insertSummaryValues,
  insertTableForBaseline,
  insertTable,
  insertStationGraph,
  insertStationImage,
  insertServiceTop6MenuItemTable,
  initialiseSlide,
};
