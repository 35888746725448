import Pptxgen from 'pptxgenjs';
import { DateObject } from 'react-multi-date-picker';
import { User } from '@auth0/auth0-spa-js';

import {
  ARR_SLIDE_ORDER_TEMPLATE,
  MAP_GRAPH_PREFIX_BY_HEADER_TEXT,
  SLIDE_CONTENT_TYPE,
  SLIDE_STYLE,
  SLIDE_TYPE,
  WEIGHT_COST_TYPE,
} from '../../constant/LocationMonthlyReviewPowerpoint';
import {
  insertGraph,
  insertImage,
  insertIssueImageSlideForAllServices,
  insertServiceTop6MenuItemTable,
  insertStationGraph,
  insertStationImage,
  insertTable,
  initialiseSlide,
} from '../../functions/PowerpointFunctions';
import { LocationMonthlyReportData, Service } from '../../interfaces';

const NUMBER_OF_IMAGES_DISPLAYED_FOR_PLATE_WASTE_SERVICE = 12;
const NUMBER_OF_IMAGES_DISPLAYED_FOR_NON_PLATE_WASTE_SERVICE = 4;

/**
 * This function holds the logic required to extract the locationData provided by the backend and plots them within
 * a predetermined slide order template structure
 * @param fetchGraphRes - Response from backend containing information required to be plot into the powerpoint slides
 * @param formState - Report form header values used for powerpoint slide headers
 * @param user - User provided by Auth0 SDK to place the user's email address in the closing slide
 */
const downloadLocationMonthlyReviewPowerpoint = async (
  fetchGraphRes: any,
  formState: {
    companyField: string;
    restaurantField: string;
    locationField: string;
    arrSelectedService: Array<Service>;
    dateRange: Array<DateObject>;
    isTagged: boolean | string | undefined;
    includeIssueSlides: boolean;
  },
  user: User | undefined
) => {
  const { locationData, restaurantService, fileName } = fetchGraphRes;
  const { dateRange, includeIssueSlides } = formState;
  const {
    overview: overviewData,
    breakdown: {
      group: arrGroupedServiceMonthlyReportData,
      individual: arrServiceMonthlyReportData,
    },
  } = locationData as LocationMonthlyReportData;
  const restaurantName = restaurantService.name;
  const locationName = restaurantService.arrLocationService[0].name;
  const currency = restaurantService.arrLocationService[0].arrService[0].currency;
  const isNoGroup =
    overviewData.table.byWeight.individual.length === overviewData.table.byWeight.group.length;
  // Powerpoint creation
  const pres = new Pptxgen();
  ARR_SLIDE_ORDER_TEMPLATE.forEach((slideTemplate) => {
    const { slideStyle } = slideTemplate;
    // Opening Slide
    if (slideStyle === SLIDE_STYLE.opening) {
      const openingText = `${restaurantName}\n${locationName}\nMonthly Review (${dateRange[1].format(
        'MMMM-YYYY'
      )})`;
      initialiseSlide(pres, slideStyle, openingText);
      return;
    }
    // Section Slide
    else if (slideStyle === SLIDE_STYLE.section) {
      const { headerText } = slideTemplate;
      initialiseSlide(pres, slideStyle, headerText);
      return;
    }
    // Sub-section Slide
    else if (slideStyle === SLIDE_STYLE.subSection && includeIssueSlides) {
      const { headerText } = slideTemplate;
      initialiseSlide(pres, slideStyle, headerText);
    }
    // Closing Slide
    else if (slideStyle === SLIDE_STYLE.closing) {
      initialiseSlide(pres, slideStyle, user?.email);
    }
    // Plain Slide
    else if (slideStyle === SLIDE_STYLE.plain) {
      const { dynamic, grouped, slideContentType } = slideTemplate;
      // For slides that do not require multiple service iterations
      if (!dynamic) {
        const { headerText } = slideTemplate;
        let slide = initialiseSlide(pres, slideStyle, headerText);
        const graphName = MAP_GRAPH_PREFIX_BY_HEADER_TEXT.get(headerText!)!;
        insertGraph(slide, overviewData.graph[graphName], headerText!);
      } else if (slideContentType === SLIDE_CONTENT_TYPE.summaryTable) {
        // If there is no grouping of services, there is no need to have the summary table for the grouped service
        // as it will be a repeat of the ungrouped table
        if (grouped && isNoGroup) {
          return;
        }

        const { arrSlideProp } = slideTemplate;
        arrSlideProp?.forEach(
          (slideProp: {
            headerText: string;
            slideType: string;
            weightCostType?: string;
            isFullWidth?: boolean;
            isExcludeForProduction?: boolean;
          }) => {
            const { headerText } = slideProp;
            let slide = initialiseSlide(pres, slideStyle, headerText);
            insertTable(pres, slide, headerText, {
              currency,
              arrReductionInWeightSummary: grouped
                ? overviewData.table.byWeight.group
                : overviewData.table.byWeight.individual,
              arrWastePerCoverSummary: grouped
                ? overviewData.table.byWeightPerCover.group
                : overviewData.table.byWeightPerCover.individual,
              arrReductionInCostSummary: grouped
                ? overviewData.table.byCost.group
                : overviewData.table.byCost.individual,
            });
          }
        );
      } else if (slideContentType === SLIDE_CONTENT_TYPE.graph) {
        const { arrSlideProp } = slideTemplate;
        arrGroupedServiceMonthlyReportData.forEach((groupedServiceMonthlyReportData) => {
          const {
            name: group,
            graph,
            isOnlyProduction,
            wastePerCoverSummary,
          } = groupedServiceMonthlyReportData;
          arrSlideProp?.forEach(
            (slideProp: {
              headerText: string;
              slideType: string;
              weightCostType?: string;
              isFullWidth?: boolean;
              isExcludeForProduction?: boolean;
            }) => {
              const { headerText, isFullWidth, isExcludeForProduction } = slideProp;
              // If service is production, do not generate cover related slides
              // Note: Currently, weight and throw mixed graph is also not created for production service as the backend code does not generate daily graphs
              // for production service. Can be considered in future.
              if (isOnlyProduction && isExcludeForProduction) {
                return;
              }
              let slide = initialiseSlide(
                pres,
                slideStyle,
                `${group.toUpperCase()} - ${headerText}`
              );
              // SLIDE TYPE: GRAPH
              const graphName = `${MAP_GRAPH_PREFIX_BY_HEADER_TEXT.get(headerText)}_For_${group}`;
              insertGraph(slide, graph[graphName], headerText, isFullWidth, wastePerCoverSummary);
            }
          );
        });
      } else if (slideContentType === SLIDE_CONTENT_TYPE.stationAndMenuItem) {
        const { arrSlideProp } = slideTemplate;
        // For slides that require multiple service iterations
        arrServiceMonthlyReportData.forEach((serviceMonthlyReportData) => {
          const {
            name,
            type,
            topStation: topStationData,
            topMenuItem: topMenuItemData,
          } = serviceMonthlyReportData;
          arrSlideProp?.forEach(
            (slideProp: {
              headerText: string;
              slideType: string;
              weightCostType?: string;
              isFullWidth?: boolean;
              isExcludeForProduction?: boolean;
            }) => {
              const { headerText, slideType } = slideProp;
              // If service is plate waste, do not generate station slides
              if (
                type === 'Plate Waste' &&
                (headerText === 'TOP 3 LEFTOVER IN TERMS OF WEIGHT (BY STATIONS)' ||
                  headerText === 'TOP 3 LEFTOVER IN TERMS OF COST (BY STATIONS)')
              ) {
                return;
              }
              let slide = initialiseSlide(
                pres,
                slideStyle,
                `${name.toUpperCase()} - ${headerText}`
              );
              // SLIDE TYPE: IMAGE
              if (slideType === SLIDE_TYPE.image) {
                const { weightCostType } = slideProp;
                const numberOfImagesToDisplay =
                  type === 'Plate Waste'
                    ? NUMBER_OF_IMAGES_DISPLAYED_FOR_PLATE_WASTE_SERVICE
                    : NUMBER_OF_IMAGES_DISPLAYED_FOR_NON_PLATE_WASTE_SERVICE;
                const isWeight = weightCostType === WEIGHT_COST_TYPE.weight;
                insertImage(
                  pres,
                  slide,
                  isWeight
                    ? topMenuItemData.byWeight.finalMonth
                    : topMenuItemData.byCost.finalMonth,
                  `${name.toUpperCase()} - ${headerText}`,
                  currency,
                  numberOfImagesToDisplay,
                  isWeight
                );
              }
              // SLIDE TYPE: STATION
              else if (slideType === SLIDE_TYPE.station) {
                if (type !== 'Plate Waste') {
                  const { weightCostType } = slideProp;
                  if (weightCostType === WEIGHT_COST_TYPE.weight) {
                    // Only top 3 station is displayed
                    insertStationGraph(slide, topStationData.byWeight.graph);
                    insertStationImage(slide, topStationData.byWeight.value);
                  } else if (weightCostType === WEIGHT_COST_TYPE.cost) {
                    // Only top 3 station is displayed
                    insertStationGraph(slide, topStationData.byCost.graph);
                    insertStationImage(slide, topStationData.byCost.value, currency);
                  }
                }
              }
              // SLIDE TYPE: TABLE
              else if (slideType === SLIDE_TYPE.table) {
                const { weightCostType } = slideProp;
                if (weightCostType === WEIGHT_COST_TYPE.weight) {
                  insertServiceTop6MenuItemTable(
                    slide,
                    'Top 6 High Volume Items',
                    currency,
                    topMenuItemData.byWeight.finalMonth,
                    topMenuItemData.byWeight.previousMonth,
                    {
                      isProductionService: type === 'Production',
                      isWeight: weightCostType === WEIGHT_COST_TYPE.weight,
                    }
                  );
                } else if (weightCostType === WEIGHT_COST_TYPE.cost) {
                  insertServiceTop6MenuItemTable(
                    slide,
                    'Top 6 High Value Items',
                    currency,
                    topMenuItemData.byCost.finalMonth,
                    topMenuItemData.byCost.previousMonth,
                    {
                      isProductionService: type === 'Production',
                      isWeight: weightCostType === WEIGHT_COST_TYPE.weight,
                    }
                  );
                }
              }
            }
          );
        });
        // For all types of issue waste analysis
      } else if (includeIssueSlides) {
        const { arrSlideProp } = slideTemplate;
        // For slides that require multiple service iterations
        arrSlideProp?.forEach((slideProp) => {
          const { headerText } = slideProp;
          let arrIssueWasteAnalysis = [];
          if (headerText === 'SINGULAR THROWS') {
            arrIssueWasteAnalysis = arrServiceMonthlyReportData.map(
              (serviceMonthlyReportData) =>
                serviceMonthlyReportData.issueWasteAnalysisByWeight.notInMenu
            );
          } else if (headerText === 'MIXED THROWS') {
            arrIssueWasteAnalysis = arrServiceMonthlyReportData.map(
              (serviceMonthlyReportData) =>
                serviceMonthlyReportData.issueWasteAnalysisByWeight.mixed
            );
          } else {
            arrIssueWasteAnalysis = arrServiceMonthlyReportData.map(
              (serviceMonthlyReportData) =>
                serviceMonthlyReportData.issueWasteAnalysisByWeight.usageIssue
            );
          }
          insertIssueImageSlideForAllServices(pres, headerText, arrIssueWasteAnalysis);
        });
      }
    }
  });
  await pres.writeFile({ fileName });
};

export default downloadLocationMonthlyReviewPowerpoint;
